/*
 * RouteRestaurantOeffnungszeiten.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import ScNavBarSpacer from "../../components/ScNavBarSpacer/ScNavBarSpacer";
import ScFooter from "../../components/ScFooter/ScFooter";
import {Container} from "react-bootstrap";
import ScOeffnungszeiten from "../../components/ScOeffnungszeiten/ScOeffnungszeiten";

export default class RouteRestaurantOeffnungszeiten extends Component {

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.
  }

  render() {
    return (
      <div>
        <Helmet>
            <title>Seecampingplatz BUM » Restaurant Seeblick » Öffnungszeiten</title>
            <meta name="description" content="Unsere Öffnungszeiten" />
        </Helmet>
        <ScNavBar />
        <ScNavBarSpacer/>
        <Container className={"mt-5"}>

          <h1 className={"text-center"}>Öffnungszeiten Restaurant Seeblick</h1>

          <p className={"text-center"}>
            <strong>Öffnungszeiten an Feiertagen nach Aushang!</strong>
          </p>

          <ScOeffnungszeiten pageTitle="" apiEndpoint={'oeffnungszeiten/1'}/>
        </Container>
        <ScFooter/>
      </div>
    );
  }
}

