/*
 * ScTextbox.js
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, {Component} from "react";
import {ScConfig} from "../ScConfig/ScConfig";
import PropTypes from "prop-types";
import './ScTextbox.scss';
import ScParseMarkdownExtra from "../ScParseMarkdownExtra/ScParseMarkdownExtra";

/**
 * Textbox
 */
export class ScTextbox extends Component {
  constructor(props){
    super(props);
    this.state = {
      Text: '',
    }
  }

  componentDidMount() {
    let apiEndPoint = ScConfig.getApiEndpoint() + 'de/cms/textboxen/' + this.props.id;
    fetch(apiEndPoint)
      .then(res => res.json())
      .then(
        (result) => {
          if(typeof result.result !== 'undefined') {
            this.setState({
              Text: result.result.Text,
            });
          }
        },
        (error) => {console.log(error);}
      );
  }

  render() {
    if(typeof this.state.Text !== "string" || this.state.Text.length <= 1) {
      return null;
    }

    return (
      <div className={"sc-textbox " + this.props.cssClassWrapper}>
        <div className={this.props.cssClassBox}>
          <ScParseMarkdownExtra>{this.state.Text}</ScParseMarkdownExtra>
        </div>
        {this.props.children}
      </div>
    )
  }
}
ScTextbox.propType = {
  id: PropTypes.number.isRequired,
  cssClassWrapper: PropTypes.string.isRequired,
  cssClassBox: PropTypes.string.isRequired,
}