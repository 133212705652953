/*
 * ScMenuTop.js
 *
 * @version 02.12.20, 23:33
 * @since 02.12.20, 23:33
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, {Component} from 'react';
import {ScConfig} from "../ScConfig/ScConfig";
import NavDropdown from "react-bootstrap/NavDropdown";
import {XKey} from "../ScToolbox/ScToolbox";
import {Link} from "react-router-dom";
import Nav from "react-bootstrap/Nav";

export default class ScMenuTop extends Component {
  hasSubmenu;
  SubMenu;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    };
    this.loadMenuTop();
  }

  loadMenuTop() {
    fetch(ScConfig.getApiEndpoint() + 'de/cms/menu/top')
      .then(res => res.json())
      .then(
        (result) => {
          if(typeof result.result !== 'undefined') {
            this.setState({
              items: result.result,
              isLoaded: true,
            })
          }
        },
        (error) => {console.log(error);}
      );
  }

  render() {
    if(!this.state.isLoaded) return null;
    return (
      <Nav className="mr-auto">
        {this.state.items.map((menuMain)=>{
          let dropdownId = -1;
          if(menuMain.Type==='Menu') {
            if(menuMain.hasSubmenu) {
              dropdownId++;
              return(
                <NavDropdown title={menuMain.Title} id={"dropdown-" + dropdownId.toString()} key={XKey.getUniqueKey()}>
                  {menuMain.SubMenu.map((menuSub)=>{
                    if(menuSub.Type==='Separator') {
                      return(<NavDropdown.Divider key={XKey.getUniqueKey()}/>);
                    } else {
                      return(
                        <NavDropdown.Item as={Link} to={menuSub.Url} key={XKey.getUniqueKey()}>
                          {menuSub.Title}
                        </NavDropdown.Item>
                      );
                    }
                  })
                  }
                </NavDropdown>
              );
            } else {
              return(
                <Nav.Link as={Link} to={menuMain.Url} key={XKey.getUniqueKey()}>
                  {menuMain.Title}
                </Nav.Link>
              );
            }
          } else {
            return null;
          }
        })}
      </Nav>
    );
  }
}
