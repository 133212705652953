import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import ScNavBar from "../components/ScNavBar/ScNavBar";
import ScNavBarSpacer from "../components/ScNavBarSpacer/ScNavBarSpacer";
import ScFooter from "../components/ScFooter/ScFooter";
import {Button, Col, Container, Row} from "react-bootstrap";
import DialogMapLegend from "../components/dialog/DialogMapLegend";
import DialogMapQrCode from "../components/dialog/DialogMapQrCode";

export default class RouteCampingplatzkarte extends Component {

  constructor(props) {
    super(props);
    this.dialogMapLegend = React.createRef()
    this.dialogMapQrCode = React.createRef()
  }

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.
  }

  downloadPdf() {
    window.open("https://assets.sascha-schneider.com/misc/scb/card/campingplatzkarte-bum.pdf")
  }

  render() {
    return (
      <div>

        <Helmet>
            <title>Seecampingplatz BUM » Campingplatzkarte</title>
            <meta name="description" content="Übersicht über die Parzellen auf unserem Campingplatz." />
        </Helmet>

        <ScNavBar />
        <ScNavBarSpacer/>

        <Container fluid className={"mt-3"}>
          <Row>
            <Col md={6}>
              <h1 className="m-0 mb-3 mb-md-0">Campingplatzkarte</h1>
            </Col>
            <Col md={6} className="text-right">

              <Button variant="outline-secondary"
                      className="mr-2"
                      size="sm"
                      onClick={()=>this.dialogMapQrCode.current.dialogShow()}>
                <i className="fa fa-qrcode"/>
              </Button>

              <Button variant="primary"
                      className="mr-2"
                      size="sm"
                      onClick={()=>this.dialogMapLegend.current.dialogShow()}>
                <i className="fa fa-info-circle"/> Legende
              </Button>

              <Button variant="danger"
                      size="sm"
                      onClick={()=>this.downloadPdf()}>
                <i className="fa fa-file-pdf-o"/> Karte als PDF downloaden
              </Button>
            </Col>
          </Row>
        </Container>

        <Container fluid className={"mt-1"}>

          <div>
            <img className="card-img-top thumbnail"
                 alt={"Campingplatzkarte"}
                 src={"https://assets.sascha-schneider.com/misc/scb/card/campingplatzkarte-bum-homepage.svg"}
            />
          </div>



          <div className="text-md-right">
            <small>
              Grafik: <a href="https://sascha-schneider.com"
                         target="_blank"
                         rel="noreferrer noopener">
                Sascha Schneider
              </a><br/>
              <strong>Stand: 2.1.2023</strong><br/>
              Alle Angaben ohne Gewähr!
            </small>
          </div>

        </Container>

        <ScFooter/>

        <DialogMapLegend ref={this.dialogMapLegend}/>
        <DialogMapQrCode ref={this.dialogMapQrCode}/>

      </div>
    );
  }
}

