/*
 * ScNavBarSpacer.js
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import './ScNavBarSpacer.scss';

export default class ScNavBarSpacer extends Component {

  render() {
    return (
      <div>
        <div className="sc-navbar-spacer">

        </div>
      </div>
    );
  }
}