/*
 * RouteRestaurant.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import {Col, Container, Row} from "react-bootstrap";
import {LazyLoadImage} from "react-lazy-load-image-component";
import ScFooter from "../../components/ScFooter/ScFooter";
import ScPRestaurantHero from "./ScPRestaurantHero";
import image1 from "../../../assets/images/restaurantseeblick-11.jpg";
import image2 from "../../../assets/images/restaurantseeblick-12.jpg";
import image3 from "../../../assets/images/restaurantseeblick-13.jpg";
import image21 from "../../../assets/images/festzelt-21.jpg";
import image22 from "../../../assets/images/festzelt-22.jpg";
import image23 from "../../../assets/images/festzelt-23.jpg";

export default class RouteRestaurant extends Component {

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.
  }

  render() {
    return (
      <div>
        <Helmet>
            <title>Seecamping BUM » Restaurant Seeblick</title>
            <meta name="description" content="Restaurant Seeblick am Borgdorfer See in Borgdorf-Seedorf bei Nortorf" />
        </Helmet>
        <ScNavBar />

        <ScPRestaurantHero/>

        <Container className="my-5">

          <h2 className="h1 text-center">Restaurant</h2>

          <Row className="mt-5">
            <Col lg={4}>

                <LazyLoadImage className="card-img-top thumbnail shadow-sm rounded"
                               alt={'Restaurant Seeblick - Gedeckter Tisch'}
                               src={image1}/>
                <p className="mt-3 mb-5">In unserem Restaurant Seeblick auf dem Seecampingplatz BUM bieten wir Ihnen 47
                  Sitzplätze im Innenbereich...
                  </p>
            </Col>
            <Col lg={4} className="">
                <LazyLoadImage className="card-img-top thumbnail shadow-sm rounded"
                               alt={'Restaurant Seeblick - Fenster zum See'}
                               src={image3}/>

                <p className="mt-3 mb-5">...sowie weitere Sitzplätze im Biergarten und auf der Seeterrasse mit
                  wunderschönem Blick auf den Borgdorfer See.</p>
            </Col>
            <Col lg={4}>
                <LazyLoadImage className="card-img-top thumbnail shadow-sm rounded"
                               alt={'Restaurant Seeblick - Innenbereich'}
                               src={image2}
                />

                <p className="mt-3 mb-5">Wir kochen gutbürgerliche Küche mit abwechslungsreichen Saisonkarten sowie
                  Themenabenden.</p>
            </Col>
          </Row>
        </Container>

        <Container className="my-5">

          <h2 className="h1 text-center">Festzelt</h2>
          <Row className="mt-5">
            <Col lg={4}>

              <LazyLoadImage className="card-img-top thumbnail shadow-sm rounded"
                             alt={'Restaurant Seeblick - Festzelt'}
                             src={image23}/>
              <p className="mt-3 mb-5">
              </p>
            </Col>
            <Col lg={4} className="">
              <LazyLoadImage className="card-img-top thumbnail shadow-sm rounded"
                             alt={'Restaurant Seeblick - Festzelt'}
                             src={image21}/>

              <p className="mt-3 mb-5"></p>
            </Col>
            <Col lg={4}>
              <LazyLoadImage className="card-img-top thumbnail shadow-sm rounded"
                             alt={'Restaurant Seeblick - Festzelt'}
                             src={image22}
              />

              <p className="mt-3 mb-5"></p>
            </Col>
          </Row>
        </Container>

        <ScFooter/>

      </div>
    );
  }
}

