/*
 * App.js
 *
 * @version 05.10.20, 16:16
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react'
import './assets/bootstrap/css/bootstrap.min.css'
import './assets/font-awesome/css/font-awesome.min.css'
import './App.scss'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import RoutePartyservice from "./app/routes/RouteGastronomie/RoutePartyservice"
import RoutePartyserviceKarte from "./app/routes/RouteGastronomie/RoutePartyserviceKarte"
import RouteFrontPage from "./app/routes/RouteFrontPage/RouteFrontPage"
import RouteMietunterkuenfte from "./app/routes/RouteMietunterkuenfte/RouteMietunterkuenfte"
import RouteOeffnungszeiten from "./app/routes/RouteOeffnungszeiten/RouteOeffnungszeiten"
import RoutePlatzkarte from "./app/routes/RoutePlatzkarte/RoutePlatzkarte"
import RoutePreisliste from "./app/routes/RoutePreisliste/RoutePreisliste"
import RouteRestaurantOeffnungszeiten from "./app/routes/RouteGastronomie/RouteRestaurantOeffnungszeiten"
import RouteRestaurantSpeisekarteAusserhaus from "./app/routes/RouteGastronomie/RouteRestaurantSpeisekarteAusserhaus"
import RouteRestaurantSpeisekarteRestaurant from "./app/routes/RouteGastronomie/RouteRestaurantSpeisekarteRestaurant"
import RouteRestaurant from "./app/routes/RouteGastronomie/RouteRestaurant"
import RouteVeranstaltungen from "./app/routes/RouteGastronomie/RouteVeranstaltungen"
import RouteStellplaetze from "./app/routes/RouteStellplaetze/RouteStellplaetze"
import Route404 from "./app/routes/Route404/Route404"
import RouteContent from "./app/routes/RouteContent/RouteContent"
import RouteCampingplatzkarte from "./app/routes/RouteCampingplatzkarte"

class App extends Component {

  slug;

  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/" exact component={RouteFrontPage} />
            <Route path="/de/:slug" render={(routeProps)=>
              <RouteContent {...routeProps} key={routeProps.match.params.slug}/>}/>
            <Route path="/campingplatzkarte" component={RouteCampingplatzkarte} />
            <Route path="/mietunterkuenfte" component={RouteMietunterkuenfte} />
            <Route path="/oeffnungszeiten" component={RouteOeffnungszeiten} />
            <Route path="/partyservice/partyservicekarte" component={RoutePartyserviceKarte} />
            <Route path="/partyservice" component={RoutePartyservice} />
            <Route path="/platzkarte" component={RoutePlatzkarte} />
            <Route path="/preise" component={RoutePreisliste} />
            <Route path="/restaurant/oeffnungszeiten" component={RouteRestaurantOeffnungszeiten} />
            <Route path="/restaurant/speisekarte/ausserhaus" component={RouteRestaurantSpeisekarteAusserhaus} />
            <Route path="/restaurant/speisekarte/restaurant" component={RouteRestaurantSpeisekarteRestaurant} />
            <Route path="/restaurant" component={RouteRestaurant} />
            <Route path="/veranstaltungen" component={RouteVeranstaltungen} />
            <Route path="/stellplaetze" component={RouteStellplaetze} />
            <Route component={Route404} />
          </Switch>
        </Router>
      </div>
     );
  }

}

export default App;
