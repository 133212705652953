/*
 * RouteImpressum.js
 * @version 26.09.20, 22:13
 * @copyright © 1997-2020 wwwdata.org
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {ScConfig} from "../../components/ScConfig/ScConfig";
import {XApi} from "../../components/X/XApi";
import ScHeadSeo from "../../components/ScHeadSeo/ScHeadSeo";
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import ScNavBarSpacer from "../../components/ScNavBarSpacer/ScNavBarSpacer";
import Container from "react-bootstrap/Container";
import ReactMarkdown from "react-markdown";
import ScFooter from "../../components/ScFooter/ScFooter";
import Route404 from "../Route404/Route404";
import {ScLoadingSpinner} from "../../components/ScToolbox/ScToolbox";

export default class RouteContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: null,
      error404: false,
    };
    this.loadContent();
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  loadContent() {
    let apiEndPoint = ScConfig.getApiEndpoint() + 'de/cms/content/' + this.props.match.params.slug;

    let promises = [
      {"field":"Title","type":"string"},
      {"field":"Beschreibung","type":"string"},
      {"field":"Sprache","type":"string"},
      {"field":"Content","type":"string"},
    ];

    fetch(apiEndPoint)
      .then(res => res.json())
      .then(
        (result) => {
          if(XApi.promises(promises,result.result)) {
            this.setState({
              isLoaded: true,
              data: result.result,
              error404: false,
            });
          } else {
            this.setState({
              data: null,
              isLoaded: true,
              error404: true
            });
          }
        },
        (error) => {this.setState({isLoaded: true,error});}
      )
  }

  render() {
    if(!this.state.isLoaded) {return (<ScLoadingSpinner/>);}
    if(this.state.error404) {return(<Route404/>);}
    return(
      <div>
        <ScHeadSeo title={[this.state.data.Title]}
                   description={this.state.data.Beschreibung}
                   locale={this.state.data.Sprache}/>
        <ScNavBar/>
        <ScNavBarSpacer/>

        <Container className="mt-1 pt-2 pb-1 bg-white sc-default-page-md">
          <ReactMarkdown source={this.state.data.Content}/>
        </Container>

        <ScFooter/>
      </div>
    );
  }
}
