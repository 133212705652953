/*
 * RouteVeranstaltungen.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import ScNavBarSpacer from "../../components/ScNavBarSpacer/ScNavBarSpacer";
import ScFooter from "../../components/ScFooter/ScFooter";
import {Row, Col, Container, ListGroup, Badge} from "react-bootstrap";
import {ScTextbox} from "../../components/ScTextbox/ScTextbox";
import {ScConfig} from "../../components/ScConfig/ScConfig";
import IfTrue from "../../components/If/IfTrue";
import ReactMarkdown from "react-markdown/with-html";
import "./RouteVeranstaltungen.scss";

export default class RouteVeranstaltungen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.

    let apiEndPoint = ScConfig.getApiEndpoint() + 'v4/calendar/event/website/list_all';

    fetch(apiEndPoint)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    return (
      <div style={{backgroundColor: "#f5f5f5"}}>

        <Helmet>
            <title>Seecampingplatz BUM » Veranstaltungen</title>
            <meta name="description" content="Veranstaltungen für unsere Camper und Gäste auf dem Seecampingplatz BUM
            in Borgdorf-Seedorf." />
        </Helmet>

        <ScNavBar />
        <ScNavBarSpacer/>

        <Container>

          <ScTextbox id={4} cssClassWrapper="alert alert-danger mt-5 sc-no-margin-bottom-last-child" cssClassBox="text-center"/>

          <h1 className="text-center">Veranstaltungen</h1>

          <div className="text-center">auf dem Seecampingplatz BUM und im Restaurant Seeblick</div>

          {/*<Row>*/}
          {/*  <Col md={2}/>*/}
          {/*  <Col md={8}>*/}
          {/*    <ListGroup className={"shadow-sm mt-4 mb-4"}>*/}
          {/*      <ListGroup.Item className="p-1">*/}
          {/*        <div className="text-center h5 p-3">*/}
          {/*          Zwischen dem 6.9.2021 und dem 15.9.2021<br/>*/}
          {/*          ist das Restaurant Seeblick wegen Urlaub geschlossen!*/}
          {/*        </div>*/}
          {/*      </ListGroup.Item>*/}
          {/*    </ListGroup>*/}
          {/*  </Col>*/}
          {/*  <Col md={2}/>*/}
          {/*</Row>*/}

          <IfTrue value={this.state.items.length === 0 && this.state.isLoaded}>
            <div className="text-center">
              <small><em>Es sind aktuell keine Veranstaltungen geplant ...</em></small>
            </div>
          </IfTrue>

          <Row>
            <Col md={2}/>
            <Col md={8}>
              {this.state.items.map(item => {
                return (
                  <div key={"calendar-events-month-" + item.year + item.month}>
                    <h2>{item.title}</h2>

                    {item.event.map((event)=>{
                      let css = "text-light text-center px-0 py-0 mr-2 route-veranstaltungen-avatar "
                        + (['So','Sa'].includes(event.dayOfWeek)?"bg-success":"bg-info");

                      let panel = {backgroundColor: "#ffffff"};
                      if(event.isFestwiese || event.isFestzelt) {
                        panel = {backgroundColor: "#ecffec"};
                      }

                      return(
                        <ListGroup key={"calender-event-" + event.id} className={"shadow-sm mb-1"}>
                        <ListGroup.Item className="p-1" style={panel}>
                          <div className="d-flex">
                            <div className={css} style={{
                              display: "inline-block",
                              width: "60px",
                              height: "60px",
                              minWidth: "60px",
                              minHeight: "60px",
                            }}>
                              <small>{event.dayOfWeek}.</small><br/>
                              <strong>{event.day}.{event.month}.</strong>
                            </div>
                            <div>
                              <h3 className="m-0">{event.title}</h3>
                              <div>
                                <small><strong>{event.start} - {event.end} Uhr</strong></small>
                              </div>
                              <div>

                                <IfTrue value={event.isRestaurant}>
                                  <Badge pill variant="primary" className="ml-2 mb-2">Restaurant Seeblick</Badge>
                                </IfTrue>

                                <IfTrue value={event.isFestwiese}>
                                  <Badge pill variant="success" className="ml-2 mb-2">Festwiese</Badge>
                                </IfTrue>

                                <IfTrue value={event.isFestzelt}>
                                  <Badge pill variant="secondary" className="ml-2 mb-2">Festzelt</Badge>
                                </IfTrue>

                                <IfTrue value={event.isClubraum}>
                                  <Badge pill variant="secondary" className="ml-2 mb-2">Clubraum</Badge>
                                </IfTrue>

                                <IfTrue value={event.isVorbestellung}>
                                  <Badge pill variant="danger" className="ml-2 mb-2">
                                    Nur nach Vorbestellung unter Tel. 04392-84840
                                  </Badge>
                                </IfTrue>

                              </div>
                            </div>

                          </div>

                          <IfTrue value={event.description.length > 0}>
                            <div className={"mt-2 p-2"}>
                              <ReactMarkdown source={event.description} escapeHtml={false}/>
                            </div>
                          </IfTrue>


                        </ListGroup.Item>
                        </ListGroup>
                      );
                    })}
                  </div>
                )
              })}
            </Col>
            <Col md={2}/>
          </Row>

          {/*<Row>*/}
          {/*  <Col md={2}/>*/}
          {/*  <Col md={8}>*/}
          {/*    <ListGroup className={"shadow-sm mt-4 mb-1"}>*/}
          {/*      <ListGroup.Item className="p-1">*/}

          {/*        <div className="text-center h5 p-3">*/}
          {/*          Zwischen dem 31.10.2021 und 31.3.2022<br/>*/}
          {/*          ist das Restaurant Seeblick geschlossen*/}
          {/*        </div>*/}

          {/*      </ListGroup.Item>*/}
          {/*    </ListGroup>*/}
          {/*  </Col>*/}
          {/*  <Col md={2}/>*/}
          {/*</Row>*/}




        </Container>

        <ScFooter/>

      </div>
    );
  }
}

