/*
 * ScEHero.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import './ScEHero.scss';
import hero from '../../../assets/images/hero2.jpg';
import ScArrowScrollDown from "../../components/ScArrowScrollDown/ScArrowScrollDown";
import {ScHeroInfoPrimary} from "../../components/ScHero/ScHero";
import {ScTextbox} from "../../components/ScTextbox/ScTextbox";

export default class ScEHero extends Component {

render() {
    let style = {
        backgroundImage: `url(${hero})`,
    };
    return (
        <div>
            <div className="sc-p-restaurant-hero" style={style}>

                <ScHeroInfoPrimary>
                    <p className="sc-hero-text">Camping am Borgdorfer See im Naturpark Westensee</p>
                    <p className="sc-hero-text2">Im Herzen von Schleswig-Holstein im
                        Städtedreieck Kiel - Neumünster - Rendsburg</p>
                </ScHeroInfoPrimary>

                <ScTextbox id={1} cssClassWrapper="sc-textbox-center" cssClassBox="sc-textbox-danger"/>

                {/*<ScHeroInfoDanger>Ab 18.5.2020 wieder geöffnet!</ScHeroInfoDanger>*/}

                <ScArrowScrollDown/>
            </div>
        </div>
    );
}
}