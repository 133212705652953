/*
 * XApi.js
 *
 * @version 26.11.20, 18:12
 * @since 26.11.20, 18:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

export class XApi {

  /**
   * Überprüft, ob alle Felder von der API korrekt zurückgegeben wurden.
   * @author sc@wwwdata.org
   * @param arrayField
   * @param resultFromApi
   * @returns {boolean}
   */
  static promises(arrayField, resultFromApi) {
    // Falls result.result nicht existiert.
    if(typeof(resultFromApi)==='undefined') {
      return false;
    }

    // Prüfe ob alle Felder mit dem richtigen Typen vorhanden sind.
    let result = true;
    arrayField.forEach(element => {
      if(typeof(resultFromApi[element.field])!==element.type) {
        result = false;
      }
    });

    return result;
  }

}