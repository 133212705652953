/*
 * RouteMietunterkuenfte.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import ScNavBarSpacer from "../../components/ScNavBarSpacer/ScNavBarSpacer";
import ScFooter from "../../components/ScFooter/ScFooter";
import {Col, Container, Row} from "react-bootstrap";
import ScEMietunterkunftTile from "../../elements/ScEMietunterkunftTile/ScEMietunterkunftTile";
import {XKey} from "../../components/ScToolbox/ScToolbox";
import {ScConfig} from "../../components/ScConfig/ScConfig";
import { ScTextbox } from '../../components/ScTextbox/ScTextbox'

export default class RouteMietunterkuenfte extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.

    let apiEndPoint = ScConfig.getApiEndpoint() + 'parzellen';

    fetch(apiEndPoint)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    return (
      <div>

        <Helmet>
            <title>Seecampingplatz BUM » Mietunterkünfte</title>
            <meta name="description" content="Bei uns können Sie Mobilheime und Schlafhütten mieten." />
        </Helmet>

        <ScNavBar />
        <ScNavBarSpacer/>

        <Container className={"mt-5"}>

          <h1>Mietunterkünfte</h1>


          <ScTextbox id={9}
                     cssClassWrapper="alert alert-success mt-5 sc-no-margin-bottom-last-child"
                     cssClassBox=""/>


          <Col>
            <Row>
            {this.state.items.map(item => {
              return (
                <ScEMietunterkunftTile item={item} key={XKey.getUniqueKey()}/>
              )
            })}
            </Row>
          </Col>

        </Container>

        <ScFooter/>

      </div>
    );
  }
}

