/*
 * ScArrowScrollDown.js
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import './ScArrowScrollDown.scss';

export default class ScArrowScrollDown extends Component {

    render() {
        return (
            <div>
                <div className="sc-arrow-scroll-down-wrapper">
                    <div className="sc-arrow-scroll-down">
                        <div className="arrow arrow-first"/>
                        <div className="arrow arrow-second"/>
                    </div>
                </div>
            </div>
        );
    }
}