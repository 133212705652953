/*
 * ScEHighlightsCard.js
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author f370c1a9 (SHA-256)
 * @copyright © 1997-2020 f370c1a9
 */

import React, { Component } from 'react';
import './ScEHighlightsCard.scss';
import {Card} from "react-bootstrap";

export default class ScEHighlightsCard extends Component {

  render() {
    return (
      <div className="col-md-4 p-3 d-sm-flex align-items-stretch">
        <Card className="sc-e-highlights-card shadow-sm">
          <Card.Body>
            <Card.Title><i className={"align-middle fa " + this.props.icon}/> {this.props.title}</Card.Title>
            <Card.Text>
              {this.props.children}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  }
}