/*
 * RoutePreisliste.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import ScNavBarSpacer from "../../components/ScNavBarSpacer/ScNavBarSpacer";
import ScFooter from "../../components/ScFooter/ScFooter";
import {Col, Container, Row} from "react-bootstrap";
import ScEListPreis from "../../elements/ScEListPreis/ScEListPreis";

export default class RoutePreisliste extends Component {

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.
  }

  render() {
    return (
      <div>
        <Helmet>
            <title>Seecampingplatz BUM » Preisliste</title>
            <meta name="description" content="Unsere Preise für Tagesgäste, Urlauber und Dauercamper." />
        </Helmet>
        <ScNavBar />
        <ScNavBarSpacer/>
        <Container className={"mt-5"}>
          <h1>Preisliste</h1>
          <p className="text-center small">Preisänderungen und Irrtümer vorbehalten!</p>
            <Row>
              <Col lg={12} xl={12}>
                <div className="text-center">
                  <h2 className="h1">Tagesplätze</h2>
                  <p><strong>Wir haben ganzjährig geöffnet!</strong></p>
                  <p>Die hier aufgeführten Preise gelten ab dem 1.1.2024.</p>
                </div>
                <ul className="list-group">
                  <ScEListPreis title={"Stellplatz"} preis={[
                    {text:"Pro Nacht",preis:1600},
                    ]}>
                    Inkl. Pkw-Stellplatz
                  </ScEListPreis>
                  <ScEListPreis title={"Zeltplatz"} preis={[{text:"Pro Nacht",preis:800}]}>
                    Mit Fahrrad
                  </ScEListPreis>
                  <ScEListPreis title={"Zeltplatz"} preis={[{text:"Pro Nacht",preis:1200}]}>
                    Mit Auto
                  </ScEListPreis>
                  <ScEListPreis title={"Wohnmobilstellplatz"} preis={[{text:"Pro Einheit",preis:1950}]}>
                    Inkl. 2 Personen und Entsorgung
                  </ScEListPreis>
                  <ScEListPreis title={"Personen"} preis={[{text:"Pro Nacht",preis:450}]}>
                    ab 15 Jahre
                  </ScEListPreis>
                  <ScEListPreis title={"Kinder"} preis={[{text:"Pro Nacht",preis:300}]}>
                    bis 14 Jahre
                  </ScEListPreis>
                  <ScEListPreis title={"Hund / Katze"} preis={[{text:"Pro Nacht",preis:250}]}/>
                  <ScEListPreis title={"Strom-Automat"} preis={[{text:"Pro kWh",preis:70}]}/>
                  <ScEListPreis title={"Strom-Tagespauschale"} preis={[{text:"Pro Nacht",preis:500}]}/>
                  <ScEListPreis title={"Wassersäule"} preis={[{text:"Pro 100l",preis:100}]}/>
                </ul>
              </Col>

              <Col lg={12} xl={12}>
                <div className="text-center">
                  <h2 className="h1">Saisoncamping<sup>*</sup></h2>
                  <p className="text-center">Auf Anfrage.</p>
                </div>
              </Col>
              <Col lg={12} xl={12}>

                <div className="text-center">
                  <h2 className="h1">Dauercamping<sup>*</sup></h2>
                  <p>
                    Die Mietpreise sind für ein Jahr. Die Neben-/Verbrauchskosten werden zusätzlich berechnet.<br/>
                    Weitere Einzelheiten und Preise entnehmen Sie bitte dem Aushang im Büro.
                  </p>
                </div>

                <ul className="list-group">
                  <ScEListPreis title={"Parzelle"}
                                preis={[{text:"Pro m²",preis:1000}]}>
                    Jahrespreis. Neben- und Verbrauchskosten werden zusätzlich berechnet.
                  </ScEListPreis>

                  {/*<ScEListPreis title={"Parzelle mit Seeblick"}*/}
                  {/*              preis={[{text:"Pauschal",preis:120000}]}/>*/}

                  {/*<li className="list-group-item list-group-item-secondary">*/}
                  {/*  <p>Im Mietpreis sind enthalten:</p>*/}
                  {/*  <ul>*/}
                  {/*    <li>1 PKW-Stellplatz</li>*/}
                  {/*    <li>Wohnwagen mit Vorzelt des Stellplatzmieters mit Familie (2 Erwachsene und Kinder bis zum 18.*/}
                  {/*      Lebensjahr).</li>*/}
                  {/*    <li>Überwinterung Wohnwagen.</li>*/}
                  {/*    <li>Warmwasser am Waschbecken.</li>*/}
                  {/*    <li>beheizte Sanitärhäuser.</li>*/}
                  {/*  </ul>*/}
                  {/*</li>*/}

                  <li className="list-group-item list-group-item-secondary">
                    <h3>Verbrauchskosten</h3>
                  </li>

                  <ScEListPreis title={"Strom"}
                                preis={[{text:"Pro kWh",preis:50}]}/>

                  <ScEListPreis title={"Duschen"}
                                preis={[{text:"Pro Minute",preis:30}]}/>

                  <li className="list-group-item list-group-item-secondary">
                    <h3>Jährliche Zusatzkosten</h3>
                  </li>

                  <ScEListPreis title={"Bereitstellung Stromanschluss"}
                                preis={[{text:"Pro Jahr",preis:3000}]}/>

                  <ScEListPreis title={"1 zusätzlicher Stellplatz"}
                                preis={[{text:"Pro Monat",preis:3000}]}>
                    für PKW, Wohnwagen, Wohnmobil oder Anhänger
                  </ScEListPreis>

                  <ScEListPreis title={"1 zusätzliche Person"}
                                preis={[{text:"Pro Jahr",preis:8000}]}/>

                  <ScEListPreis title={"1 Hund / Katze"}
                                preis={[{text:"Pro Jahr",preis:8000}]}/>

                  {/*<ScEListPreis title={"überwiegend hier oder hier gemeldete Person"}*/}
                  {/*              preis={[{text:"Pro Jahr",preis:10000}]}/>*/}

                  {/*<ScEListPreis title={"Dusch-Transponder"}*/}
                  {/*              preis={[{text:"Pfand",preis:1500}]}/>*/}

                </ul>

                <p><small><sup>*</sup>) weiter Einzelheiten und Preise entnehmen Sie bitten dem Aushang im Büro.</small></p>
              </Col>
            </Row>
        </Container>
        <ScFooter/>
      </div>
    );
  }
}

