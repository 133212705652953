import React,{Component} from "react";
import PropTypes from "prop-types";
import {XKey} from "../ScToolbox/ScToolbox";
import {ScConfig} from "../ScConfig/ScConfig";


export default class ScOeffnungszeiten extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    let apiEndPoint = ScConfig.getApiEndpoint() + this.props.apiEndpoint;
    fetch(apiEndPoint)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    if(this.state.items === null) {
      return null;
    }
    return(
      <div>
        <h1>{this.props.pageTitle}</h1>

        <p className="text-center my-5 font-weight-bold">{this.state.items.content}</p>
        {this.state.items.data.map(item => {
          return(
            <div className="mt-5" key={XKey.getUniqueKey()}>
              <h2>{item.title}</h2>
              <ul className="list-group">
                {item.tage.map(tag => {
                  return (
                    <li className="list-group-item" key={XKey.getUniqueKey()}>
                      <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{tag.tag}</h5>
                        <div>{tag.zeit}</div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }

}
ScOeffnungszeiten.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
}