/*
 * ScHeadSeo.js
 * @version 26.09.20, 22:13
 * @copyright © 1997-2020 wwwdata.org
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import {ScConfig} from "../ScConfig/ScConfig";

export default class ScHeadSeo extends Component {

  render() {
    let title = '';
    if(typeof (this.props.title) === 'string') {
      title = this.props.title;
    } else if(Array.isArray(this.props.title)) {
      let titleArray = [ScConfig.getTitleCustomer()].concat(this.props.title);
      title = titleArray.join(' » ');
    }

    let description = this.props.description.substr(0, 320);
    let twitterName = ScConfig.getTwitterName();
    return(
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:locale" content={this.props.locale} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content={ScConfig.getTitleCustomer()} />
        {/*Google / Search Engine Tags*/}
        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} />
        {typeof(this.props.image) === "string" &&
        <meta itemProp="image" content={this.props.image} />
        }
        {/*Facebook Meta Tags*/}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {typeof(this.props.image) === "string" &&
        <meta property="og:image" content={this.props.image} />
        }
        {/*Twitter Meta Tags*/}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {typeof (this.props.image) === "string" &&
        <meta name="twitter:image" content={this.props.image} />
        }
        {typeof (twitterName) === "string" &&
        <meta name="twitter:site" content={twitterName}/>
        }
        {typeof (twitterName) === "string" &&
        <meta name="twitter:creator" content={twitterName} />
        }
      </Helmet>
    );
  }
}
ScHeadSeo.propTypes = {
  title: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  image: PropTypes.string,
}