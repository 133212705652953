/*
 * ScSpeisekarte.js
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, {Component} from "react";
import './ScSpeisekarte.scss';
import {XFormat, XKey, XMap} from "../ScToolbox/ScToolbox";
import {Col, Row} from "react-bootstrap";
import PropTypes from 'prop-types';
import {ScConfig} from "../ScConfig/ScConfig";

export class ScSpeisekarte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.
    let apiEndPoint = ScConfig.getApiEndpoint() + 'speisekarte/' + this.props.id.toString();
    fetch(apiEndPoint)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    return (
      <div>
        <Row>
          <Col lg={2}/>
          <Col lg={8}>
            <div>
              {this.state.items.map(item => {
                return (
                  <ScSpeisekarteKategorie item={item} key={XKey.getUniqueKey()}/>
                )
              })}
            </div>
          </Col>
          <Col lg={2}/>
        </Row>
      </div>
    );
  }
}
ScSpeisekarte.propTypes = {
  id: PropTypes.number.isRequired,
}

export class ScSpeisekarteKategorie extends Component {
  render() {
    return (
      <section key={XKey.getUniqueKey()}>
        <h2 className="text-center mt-5">...{this.props.item.title}</h2>
        <ul className="list-group list-group-flush mb-5">
          {this.props.item.items.map(item => {
            return (
              <ScSpeisekarteRow item={item} key={XKey.getUniqueKey()}/>
            )
          })}
        </ul>
      </section>
    );
  }
}

export class ScSpeisekarteRow extends Component {
  render() {
    let beschreibung = '';
    if(this.props.item.beschreibung.length>0) {
      beschreibung = <div><small className="card-text ">{this.props.item.beschreibung}</small></div>;
    }
    let beschreibung_englisch = '';
    if(this.props.item.beschreibung_englisch.length>0) {
      beschreibung_englisch = <div><small className="card-text font-italic">{this.props.item.beschreibung_englisch}
      </small></div>;
    }
    return (
      <li className="list-group-item d-flex w-100 justify-content-between">
        <div>
          <h5 title="Titel" className="mb-1 font-weight-bold">{this.props.item.text}</h5>
          {beschreibung}
          {beschreibung_englisch}
          <ScSpeisekarteInfo item={this.props.item} key={XKey.getUniqueKey()}/>
        </div>
        <div className="card-text text-nowrap font-weight-bold pl-3">{XFormat.currency(this.props.item.preis)} €</div>
      </li>
    );
  }
}

export class ScSpeisekarteInfo extends Component {
  constructor(props){
    super(props);
    this.state = {
      open: false
    }
    this.togglePanel = this.togglePanel.bind(this);
  }

  togglePanel(e){
    this.setState({open: !this.state.open})
  }

  render() {
    let count_zusatzstoffe = XMap.getCount(this.props.item.zusatzstoffe);
    let count_allergene = XMap.getCount(this.props.item.allergene);
    if(count_zusatzstoffe + count_allergene === 0) {
      return null;
    } else {
      let zusatzstoffe = null;
      if(count_zusatzstoffe>0) {
        zusatzstoffe = <div>
          <h5>enthält folgende Zusatzstoffe:</h5>
          <ul>
            {this.props.item.zusatzstoffe.map(item => {
              return (
                <li item={item} key={XKey.getUniqueKey()}>- {item.title}</li>
              )
            })}
          </ul>
        </div>;
      }
      let allergene = null;
      if(count_allergene>0) {
        allergene = <div>
          <h5>enthält folgende Allergene:</h5>
          <ul>
            {this.props.item.allergene.map(item => {
              return (
                <li item={item} key={XKey.getUniqueKey()}>- {item.title}</li>
              )
            })}
          </ul>
        </div>;
      }
      if(this.state.open) {
        return(
          <div>
            <div className="sc-speisekarte-info-button"
                 title="Informationen zu Zusatzstoffen und Allergenen anzeigen."
                 onClick={()=>this.togglePanel()}>
              <i className="fa fa-caret-up"/> Zusatzstoffe und Allergene
            </div>
            <div className="sc-speisekarte-info-panel">
              {zusatzstoffe}
              {allergene}
            </div>
          </div>
        );
      } else {
        return(
          <div>
            <div className="sc-speisekarte-info-button"
                 title="Informationen zu Zusatzstoffen und Allergenen anzeigen."
                 onClick={()=>this.togglePanel()}>
              <i className="fa fa-caret-down"/> Zusatzstoffe und Allergene
            </div>
          </div>
        );
      }
    }
  }
}