import React, {Component} from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import './ScContentMD.scss';

export default class ScContentMD extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      content: null
    };
    this.loadContent();
  }

  loadContent() {
    let apiEndPoint = this.props.apiEndpoint
    fetch(apiEndPoint)
      .then(res => res.text())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            content: result
          })
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        }
      )
  }

  render() {
    if(this.state.content === null) {return null}
    let css = 'sc-content-md';
    if(typeof(this.props.center_p) === "boolean" && this.props.center_p) {
      css += ' sc-content-md-p-center';
    }

    return(
      <div className={css}>
        <ReactMarkdown source={this.state.content} linkTarget="_blank"/>
      </div>
    );
  }
}
ScContentMD.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  center_p: PropTypes.bool,
}