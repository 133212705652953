/*
 * ScNavBar.js
 *
 * @version 05.10.18, 16:17
 * @since 05.10.18, 16:10
 * @author Sascha Schneider <hello@sascha-schneider.com>
 */

import React, { Component } from 'react';
import Navbar from "react-bootstrap/Navbar";
import {Link} from "react-router-dom";
import logo from '../../../assets/images/icon-36x36.png';
import "./ScNavBar.scss";
import ScMenuTop from "../ScMenuTop/ScMenuTop";
import PropTypes from "prop-types";

export default class ScNavBar extends Component {

  render() {

    let spacer = null;
    if(typeof this.props.spacer === "boolean" && this.props.spacer) {
      spacer = <div className="sc-navbar-spacer"/>
    }

    return (
      <div>
        <Navbar collapseOnSelect bg="light" expand="lg" fixed="top" className="sc-nav-bar">
          <Navbar.Brand as={Link} to="/">
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
          />{' '}
          Seecampingplatz BUM</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <ScMenuTop/>
          </Navbar.Collapse>
        </Navbar>
        {spacer}
      </div>
    );
  }
}
ScNavBar.propTypes = {
  spacer: PropTypes.bool,
}

export class ScNavBarSpacer extends Component {

  render() {
    return (
      <div>
        <div className="sc-navbar-spacer">

        </div>
      </div>
    );
  }
}
