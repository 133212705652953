/*
 * ScEMietunterkunftTile.js
 *
 * @version 06.03.21, 23:11
 * @since 06.03.21, 23:11
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import './ScEMietunterkunftTile.scss';
import {LazyLoadImage} from "react-lazy-load-image-component";
import {Badge, Card} from "react-bootstrap";
import ScETableColPreis from "../ScETableColPreis/ScETableColPreis";
import {XKey} from "../../components/ScToolbox/ScToolbox";
import ReactMarkdown from 'react-markdown/with-html'

export default class ScEMietunterkunftTile extends Component {

  render() {

    let e_strom = null;
    if(this.props.item.e_strom) {
      e_strom = <Badge variant="primary"><i className="fa fa-flash"/> Strom</Badge>
    }

    let e_wlan = null;
    if(this.props.item.e_wlan) {
      e_wlan = <Badge variant="primary"><i className="fa fa-wifi"/> WLAN</Badge>
    }

    let e_wc = null;
    if(this.props.item.e_wc) {
      e_wc = <Badge variant="primary"><i className="fa fa-male"/><i className="fa fa-female"/> WC</Badge>
    }

    let e_kueche = null;
    if(this.props.item.e_kueche) {
      e_kueche = <Badge variant="primary"><i className="fa fa-cutlery"/> Küche</Badge>
    }

    let e_hund = null;
    if(this.props.item.e_hund) {
      e_hund = <Badge variant="primary"><i className="fa fa-paw"/> Hund erlaubt</Badge>
    } else {
      e_hund = <Badge variant="secondary"><i className="fa fa-paw"/> Hund nicht erlaubt</Badge>
    }

    let e_pkw = null;
    if(this.props.item.e_pkw===1) {
      e_pkw = <Badge variant="primary"><i className="fa fa-car"/> 1 PKW</Badge>
    }

    let e_personen = null;
    if(this.props.item.e_personen===1) {
      e_personen = <Badge variant="primary"><i className="fa fa-user"/> max. 1 Person</Badge>
    } else if(this.props.item.e_personen>1) {
      e_personen = <Badge variant="primary"><i className="fa fa-user"/> max. {this.props.item.e_personen} Personen</Badge>
    }

    let reservierungs_gif = null;
    if(typeof this.props.item.reservierungs_gif === 'string') {
      reservierungs_gif = <div><img  className="card-img-top" src={this.props.item.reservierungs_gif} alt="Belegungskalender"/></div>
    }


    return (
      <div className="sc-e-mietunterkunft-tile col-lg-4 p-3 d-sm-flex align-items-stretch">
        <Card className="shadow-sm">
          <LazyLoadImage className="card-img-top thumbnail"
                         alt={this.props.item.title}
                         src={this.props.item.thumbnail}
          />
          <Card.Body>
            <h5 className="card-title">{this.props.item.title}</h5>

            <ReactMarkdown className="card-text">{this.props.item.text}</ReactMarkdown>
            {/*<p className="card-text">{this.props.item.text}</p>*/}

          </Card.Body>

          <Card.Footer>

            {reservierungs_gif}

            <div className="sc-e-mietunterkunft-tile-e">{e_personen}{e_pkw}{e_strom}{e_wlan}{e_wc}{e_kueche}{e_hund}</div>
            <table className="sc-table-preise w-100 mt-2">
              <tbody>
              {this.props.item.preise.map(preis => {
                return (
                  <ScETableColPreis item={preis} key={XKey.getUniqueKey()}/>
                )
              })}
              </tbody>
            </table>

          </Card.Footer>

        </Card>

      </div>
    );
  }
}
