import React, {Component} from "react";
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import Container from "react-bootstrap/Container";
import ScFooter from "../../components/ScFooter/ScFooter";
import CmsHeadSeo from "../../components/Cms/CmsHeadSeo";
import CmsHero, {ScHeroInfoPrimary} from "../../components/Cms/CmsHero";
import ScContentMD from "../../components/ScContentMD/ScContentMD";
import CmsImageRow from "../../components/Cms/CmsImageRow";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default class RoutePartyservice extends Component{

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    window.scrollTo(0,0)
  }

  render() {
    return (
      <div>

        <CmsHeadSeo apiEndpoint="/cms/local/pages/partyservice/seo.json"/>
        <ScNavBar spacer={false}/>

        <CmsHero heroimage="/cms/local/pages/partyservice/hero.jpg">
          <ScHeroInfoPrimary>
            <p className="sc-hero-text"><ScContentMD apiEndpoint="/cms/local/pages/partyservice/content-hero-title.md" /></p>
            <p className="sc-hero-text2"><ScContentMD apiEndpoint="/cms/local/pages/partyservice/content-hero-description.md" /></p>
          </ScHeroInfoPrimary>
        </CmsHero>

        <Container>

          <p className="my-5">&nbsp;</p>

          <p className="my-5 text-center h1 font-weight-bold">
            <ScContentMD apiEndpoint="/cms/local/pages/partyservice/content-top.md" />
          </p>

          <p className="my-5">&nbsp;</p>

          <div className="my-5">

            <CmsImageRow image="/cms/local/pages/partyservice/content-image-div-1.jpg" alt="Image-Div 1"reverse={false}>
              <div className="h3 my-4">
                <ScContentMD apiEndpoint="/cms/local/pages/partyservice/content-image-div-1.md" />
              </div>
            </CmsImageRow>

            <p className="my-5">&nbsp;</p>

            <CmsImageRow image="/cms/local/pages/partyservice/content-image-div-2.jpg" alt="Image-Div 2" reverse={true}>
              <div className="h3 my-4">
                <ScContentMD apiEndpoint="/cms/local/pages/partyservice/content-image-div-2.md" />
              </div>
            </CmsImageRow>

          </div>

          <p className="my-5">&nbsp;</p>

          <p className="my-5 text-center h1 font-weight-bold">
            <ScContentMD apiEndpoint="/cms/local/pages/partyservice/content-bottom.md" />
          </p>

          <Row className="my-5">
            <Col sm={1}/>
            <Col sm={4}><
              Link className="mb-3 btn btn-primary btn-block font-weight-bold" to={'/partyservice/partyservicekarte'}>Partyservicekarte</Link>
            </Col>
            <Col sm={1}/>
            <Col sm={1}/>
            <Col sm={4}>
              <Link className="mb-3 btn btn-secondary btn-block font-weight-bold"  to={'/de/kontakt'}>Kontakt</Link>
            </Col>
            <Col sm={1}/>
          </Row>

        </Container>

        <ScFooter/>
      </div>
    );
  }

}