/*
 * ScParseMarkdownExtra.js
 * @version 2020.9.26
 * @copyright © 1997-2020 wwwdata.org
 * @link https://wwwdata.org
 */

import React, {Component} from "react";
import ReactMarkdown from "react-markdown/with-html";
// import './ScParseMarkdownExtra.scss';

/**
 * MarkDown-Renderer mit zusätzlichen Formatierungen.
 *
 * 1.) +++Text1+++Text2+++
 * Fügt eine Schlagzeilenleiste hinzu.
 *
 * 2.) Zusätzliche Leerzeile bei mehr als 2 Zeilenumbrüchen.
 */
export default class ScParseMarkdownExtra extends Component{
  inlineBlock;
  cssClass;

  render() {
    let content = '';
    let stack0, stack1 = false;
    this.props.children.split("\n").map(line => {
      stack1 = stack0;
      stack0 = line.trim() === "";
      if(stack0 && stack1) {
        content+= '<div>&nbsp;</div>';
      } else if(line.startsWith("+++")) {
        content+= '<div class="sc-markdown-extra-intro">' + line.replaceAll('+++','<br/>') +
          "</div>\n\n";
      } else {
        content+= line + "\n" ;
      }
      return null;
    });

    let css='sc-markdown-extra';
    if(typeof(this.props.inlineBlock)!=='undefined') {
      css+= ' d-inline-block';
    }
    if(typeof(this.props.cssClass)!=='undefined') {
      css = this.props.cssClass;
    }

    return(
      <div className={css}>
        <ReactMarkdown source={content} escapeHtml={false}/>
      </div>
    );
  }

}
