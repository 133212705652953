import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";

/**
 * Zeigt einen Dialog zum Löschen an.
 *
 * Wird im Parent-Object folgendermaßen eingebunden:
 *
 * 1.) Im Renderer:
 * ```
 *   <ScDialogConfirm ref={this.dialogDelete} onConfirm={()=>this.myDeleteMethode()}
 *     msg={"Soll dieses Element wirklich gelöscht werden?"}/>
 * ```
 *
 * 2.) In den Constructor die Referenz einfügen:
 * ```
 *   this.dialogDelete = React.createRef();
 * ```
 *
 * 2.) Den Dialog mit folgender Zeile öffnen:
 * ```
 *   this.dialogDelete.current.dialogShow()
 * ```
 *
 * @version 2021.2.5
 * @since 2021.2.5
 * @author sc@wwwdata.org
 */
export default class DialogMapLegend extends Component {

  items = [
    {id:1,icon:"urlauber.svg",text:"Urlauber"},
    {id:2,icon:"dauercamping.svg",text:"Dauercamping"},
    {id:3,icon:"mobilheimplatz.svg",text:"Mobilheimplatz"},
    {id:4,icon:"fahrbahn.svg",text:"Fahrbahn"},
    {id:5,icon:"fussgaengerweg.svg",text:"Fußgängerweg"},
    {id:6,icon:"schranke.svg",text:"Schranke / Absperrung"},
    {id:7,icon:"feuerloescher.svg",text:"Feuerlöscher"},
    {id:8,icon:"parkplatz.svg",text:"Parkplatz"},
    {id:9,icon:"gastank.svg",text:"Gastank"},
    {id:10,icon:"rezeption.svg",text:"Rezeption / Kiosk"},
    {id:11,icon:"restaurant.svg",text:"Restaurant"},
    {id:12,icon:"imbiss.svg",text:"Imbiss"},
    {id:13,icon:"toilette.svg",text:"Toilette"},
    {id:14,icon:"dusche.svg",text:"Dusche"},
    {id:15,icon:"waschmaschine.svg",text:"Waschmaschiene"},
    {id:16,icon:"muellentsorgung.svg",text:"Müllentsorgung"},
    {id:17,icon:"entsorgung-chemietoilette.svg",text:"Entsorgung Chemietoilette"},
    {id:18,icon:"entsorgung-schmutzwasser.svg",text:"Entsorgung Schmutzwasser"},
    {id:19,icon:"wifi-hotspot.svg",text:"WiFi-Hotspot"},
    {id:20,icon:"haltestelle.svg",text:"Haltestelle"},
    {id:21,icon:"grabhuegel.svg",text:"Grabhügel"},


  ]

  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
    };
  }

  dialogClose() {
    this.setState({
      show: false,
    });
  }

  dialogShow() {
    this.setState({
      show: true,
    });
  }

  onHide() {
    // Dummy-function to catch event on overlay.
  }

  /**
   * Erzeugt den View.
   * @returns {JSX.Element}
   */
  render() {
    return (
      <Modal size="md" show={this.state.show} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="align-items-center sc-modal-bg">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <Modal.Title>Legende</Modal.Title>
            <div>
              <i className="fa fa-remove sc-modal-header-close-button"
                 onClick={()=>this.dialogClose()}
                 title="Dialog schließen"/>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>

          <h2 className="mt-0 pt-0">Symbole</h2>

          <table>
            <tbody>
            {this.items.map((item)=>{
              return(
                <tr key={item.id}>
                  <td className="pr-2 text-center">
                    <img src={"/content/icons/" + item.icon} alt=""/>
                  </td>
                  <td>
                    {item.text}
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table>

          <hr/>

          <h2 className="mt-0 pt-0">Fußnoten</h2>

          <small>*) interne Wegbezeichnung, keine offizielle Postadresse.</small>

        </Modal.Body>
        <Modal.Footer className="sc-modal-bg-footer">
          <Button variant="secondary" onClick={()=>this.dialogClose()}>
            <i className="fa fa-remove"/> Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
