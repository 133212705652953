/*
 * RoutePlatzkarte.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import ScNavBarSpacer from "../../components/ScNavBarSpacer/ScNavBarSpacer";
import ScFooter from "../../components/ScFooter/ScFooter";
import {Container} from "react-bootstrap";
import {LazyLoadImage} from "react-lazy-load-image-component";
import platzkarte from '../../../assets/images/platzkarte.jpg';

export default class RoutePlatzkarte extends Component {

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.
  }

  render() {
    return (
      <div>

        <Helmet>
            <title>Seecampingplatz BUM » Platzkarte</title>
            <meta name="description" content="Übersicht über die Parzellen auf unserem Campingplatz." />
        </Helmet>

        <ScNavBar />
        <ScNavBarSpacer/>

        <Container className={"mt-5"}>

          <h1>Platzkarte</h1>


          <div>
            <LazyLoadImage className="card-img-top thumbnail"
                           alt={'Platzkarte'}
                           src={platzkarte}
            />
          </div>


        </Container>

        <ScFooter/>

      </div>
    );
  }
}

