/*
 * ScHero.js
 *
 * @version 26.07.20, 10:34
 * @since 26.07.20, 10:34
 * @author dev@webserver.ws
 * @copyright © 1997-2020 webserver.ws
 * @license All rights reserved
 * @link https://webserver.ws
 */

import React, { Component } from 'react';
import './CmsHero.scss';
import ScArrowScrollDown from "../ScArrowScrollDown/ScArrowScrollDown";
import PropTypes from "prop-types";

export default class CmsHero extends Component {

  render() {
    let style = {
      backgroundImage: `url(${this.props.heroimage})`,
    };
    return (
      <div>
        <div className="sc-p-restaurant-hero" style={style}>
          {this.props.children}
          <ScArrowScrollDown/>
        </div>
      </div>
    );
  }
}
CmsHero.propTypes = {
  heroimage: PropTypes.any.isRequired,
}

/**
 * Transparentes Panel mit primary-background.
 */
export class ScHeroInfoPrimary extends Component {
  render() {
    return (
      <div className="sc-hero-info">
        <div className="sc-hero-info-primary-text">{this.props.children}</div>
      </div>
    );
  }
}

/**
 * Transparentes Panel mit danger-background.
 */
export class ScHeroInfoDanger extends Component {
  render() {
    return (
      <div className="sc-hero-info">
          <div className="sc-hero-info-danger-text">{this.props.children}</div>
      </div>
    );
  }
}
