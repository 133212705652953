/*
 * ScToolbox.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:10
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

// PLEASE SEE README.md FOR IMPORTANT INFORMATION !!!

import React, {Component} from "react";
import PropTypes from 'prop-types';
import './ScToolbox.scss';
import {Button, Modal} from "react-bootstrap";
import packageJson from "../../../../package.json";
import {ScConfig} from "../ScConfig/ScConfig";
import {Helmet} from "react-helmet";
import ScNavBar, {ScNavBarSpacer} from "../ScNavBar/ScNavBar";

export class ScBodyDefault extends Component {
  render() {
    let pageTitle = XApp.getTitle(this.props.title);
    return(
      <div>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageTitle} />
        </Helmet>
        {this.props.navbarDefault && <ScNavbarDefault/>}
        {this.props.children}
      </div>
    );
  }
}
ScBodyDefault.propTypes = {
  title: PropTypes.array.isRequired,
  navbarDefault: PropTypes.bool,
}

export class ScNavbarDefault extends Component {
  render() {
    return(
      <>
        <ScNavBar>{this.props.children}</ScNavBar>
        <ScNavBarSpacer/>
      </>
    );
  }
}

/**
 * Hilfe-Button
 */
export class ScButtonHelp extends Component {
  render() {
    return(
      <a href={ScConfig.getHelpUrl(this.props.url)} target="_blank" rel="noopener noreferrer" className="btn btn-outline-info mr-2 mb-2">
        <i className="fa fa-question"/> Hilfe
      </a>
    );
  }
}
ScButtonHelp.propTypes = {
  url: PropTypes.string.isRequired,
}

export class ScDialogAbout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showAboutDialog: false,
    };
  }

  render() {
    return(
      <Modal show={this.state.showAboutDialog}
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        <Modal.Header>
          <Modal.Title>Über {ScConfig.getApp() + ' ' + ScConfig.getTitleCustomer()}...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Version: {packageJson.version}</div>

          <div className="mt-4 mb-0"><strong>Frontend</strong></div>
          <div>
            &copy; 2019-2020 Sascha Schneider&nbsp;
            <span className="text-nowrap d-block d-sm-inline-block">
                <a href="https://sascha-schneider.com" target="_blank" rel="noopener noreferrer">
                  &lt;sascha-schneider.com&gt;
                </a>
              </span>
          </div>

          <div className="mt-4 mb-0"><strong>Backend</strong></div>
          <div className="mt-0">
            &copy; 1997-2003 Schneider+Coen GbR&nbsp;
            <span className="text-nowrap d-block d-sm-inline-block">
                <a href="https://schneider-coen.de" target="_blank" rel="noopener noreferrer">
                  &lt;schneider-coen.de&gt;
                </a>
              </span>
          </div>
          <div>
            &copy; 2003-2020 Sascha Schneider&nbsp;
            <span className="text-nowrap d-block d-sm-inline-block">
                <a href="https://sascha-schneider.com" target="_blank" rel="noopener noreferrer">
                  &lt;sascha-schneider.com&gt;
                </a>
              </span>
          </div>
          <div className="mt-4">Lizensiert unter der&nbsp;
            <a href="http://www.gnu.org/licenses/agpl-3.0.html" target="_blank" rel="noreferrer noopener">
              AGPL-3.0-only
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>this.closeAboutDialog()}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  closeAboutDialog() {
    this.setState({
      showAboutDialog: false,
    });
  }

  showAboutDialog() {
    this.setState({
      showAboutDialog: true,
    });
  }

}

/**
 * Floating Action Button (FAB) menu.
 */
export class ScFabMenu extends Component{
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    }
    // Binde Methoden
    this.toggleOpen = this.toggleOpen.bind(this);
  }
  toggleOpen() {
    this.setState(
      {
        isOpen: !this.state.isOpen
      }
    )
  }
  render() {
    let css ='btn-group-fab';
    if(this.state.isOpen) {
      css+= ' active';
    }
    return (
      <div className={css} role="group" aria-label="FAB Menu">
        <div>
          <button type="button" className="btn btn-main btn-primary has-tooltip" data-placement="left"
                  title="FAB-Menü (Floating Action Button)" onClick={()=>this.toggleOpen()} ><i className="fa fa-bars"/>
          </button>
          {this.props.children}
        </div>
      </div>
    );
  }
}

/**
 * Interner Prototype für einen Sub-Button im Floating Action Button (FAB) menu.
 */
class ScFabMenuItem extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  render() {
    return(
      <button type="button"
              className={"btn btn-sub has-tooltip " + this.props.colors}
              data-placement="left"
              title={this.props.title}
              onClick={()=>this.handleClick()}>
        <i className={"fa " + this.props.icon}/>
      </button>
    );
  }
  handleClick() {
    if(typeof this.props.to !== 'undefined') {
      // console.log('Link');
      this.props.history.push(this.props.to);
    }
  }
}

export class ScFabMenuItemDelete extends Component {
  render() {
    return(<ScFabMenuItem title="Löschen" colors="btn-secondary" icon="fa-trash"
                     to={this.props.to} history={this.props.history}/>);
  }
}

export class ScFabMenuItemDownload extends Component {
  render() {
    return(<ScFabMenuItem title="Download" colors="btn-warning" icon="fa-download"
                     to={this.props.to} history={this.props.history}/>);
  }
}

export class ScFabMenuItemFullscreen extends Component {
  render() {
    return(<ScFabMenuItem title="Fullscreen" colors="btn-info" icon="fa-arrows-alt"
                     to={this.props.to} history={this.props.history}/>);
  }
}

/**
 * Ruft die Hilfeseite zur Component auf.
 */
export class ScFabMenuItemHelp extends Component {
  render() {
    return(<a href={ScConfig.getHelpUrl(this.props.url)} target="_blank" rel="noopener noreferrer"
              className="btn btn-sub has-tooltip btn-info" data-placement="left" title="Hilfe zu dieser Funktion">
        <i className="fa fa-question"/>
      </a>)
  }
}

export class ScFabMenuItemNew extends Component {
  render() {
    return(<ScFabMenuItem title="Neu hinzufügen" colors="btn-success" icon="fa-plus"
                     to={this.props.to} history={this.props.history}/>);
  }
}

export class ScFabMenuItemPdf extends Component {
  render() {
    return(<ScFabMenuItem title="PDF erstellen" colors="btn-warning" icon="fa-file-pdf-o"
                     to={this.props.to} history={this.props.history}/>);
  }
}

export class ScFabMenuItemPrint extends Component {
  render() {
    return(<ScFabMenuItem title="Drucken" colors="btn-warning" icon="fa-print"
                     to={this.props.to} history={this.props.history}/>);
  }
}

export class ScFabMenuItemSave extends Component {
  render() {
    return(<ScFabMenuItem title="Speichern" colors="btn-danger" icon="fa-floppy-o"
                     to={this.props.to} history={this.props.history}/>);
  }
}

export class ScFabMenuItemXls extends Component {
  render() {
    return(<ScFabMenuItem title="Excel-Tabelle exportieren" colors="btn-warning" icon="fa-file-excel-o"
                     to={this.props.to} history={this.props.history}/>);
  }
}

/**
 * Ladeanimation.
 */
export class ScLoadingSpinner extends Component {
  render() {
    return (
      <div className="sc-loading-spinner mt-5">
        <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"/>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }
}

export class ScLogo extends Component {
  render() {
    return(
      <span className="sc-logo">
        <span className="sc-logo-part1">&lt;<span className="sc-logo-part2">/</span>&gt;</span>
      </span>
    );
  }
}

export class ScLogoScm extends Component {
  render() {
    return(
      <span className="sc-logo-scm">
        <span className="sc-logo-scm-0">LoLa</span>
        <span className="sc-logo-scm-1">S</span>
        <span className="sc-logo-scm-2">C</span>
        <span className="sc-logo-scm-3">M</span>
        <sup>&copy;</sup>
      </span>
    );
  }
}

/**
 * Wandelt einen Text mit Zeilenumbrüchen in einen Text mit Absätzen um.
 */
export class ScPlainToHtml extends Component {
  render() {
    if(typeof this.props.text !== 'undefined') {
      let textArray = this.props.text.split('\n') // Zerlege text in ein Array.
      return (<div>
          {textArray.map(item => {
            if(item.length > 0) { // Nur, wenn item einen Inhalt hat um leere <p> zu vermeiden.
              return (<p key={XKey.getUniqueKey()}>{item}</p>)
            } else {return null;}
          })}
        </div>);
    } else {return null;}
  }
}
ScPlainToHtml.propTypes = {
  text: PropTypes.string.isRequired,
}

/**
 * App-spezifische Hilfsfunktionen
 */
export class XApp {

  /**
   * Gibt den vollständigen Title aus. Sofern in der ScConfig getApp() leer ist, wird dieser Parameter nicht verwendet.
   * @param array
   * @returns {string}
   */
  static getTitle(array) {
    let app = [ScConfig.getApp(), ScConfig.getTitleCustomer()];
    if(ScConfig.getApp()==='') {
      app = [ScConfig.getTitleCustomer()];
    }
    let title = app.concat(array);
    return title.join(' » ');
  }

}

export class XConsole {

  static getCopyright(app) {
    return app + "\n@version " + packageJson.version + "\n" +
      "@copyright (c) 1997-2020 wwwdata.org\n" +
      "@license All rights reserved\n" +
      "@link https://wwwdata.org\n";
  }

}

/**
 * Formatierfunktionen.
 */
export class XFormat {
  /**
   * Formatiert einen Ganzzahl-Währungswert in eine formatierte Anzeige.
   * @param int
   * @returns {string}
   */
  static currency(int) {
    return (int / 100).toFixed(2).toString().replace('.',',');
  }

  static string_shorten(text, length) {
    if(typeof text !== 'string') return '';
    let ext = '';
    if(text.length>length) ext = '...';
    return text.substr(0,length) + ext;
  }
}

export class XKey {
  static uniqueKey = -1;

  /**
   * Gibt einen individuellen Key für Listenelemente zurück.
   * @returns {number}
   */
  static getUniqueKey() {
    this.uniqueKey++;
    return this.uniqueKey;
  }
}

export class XMap {
  /**
   * Gibt die Anzahl der Elemente in einer Map zurück.
   * @param map
   * @returns {number}
   */
  static getCount(map) {
    let result = 0;
    // eslint-disable-next-line no-unused-vars
    for (let count in map) {
      result++;
    }
    return result;
  }
}


