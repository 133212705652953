/*
 * ScEHighlights.js
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {Container, Row} from "react-bootstrap";
import "./ScEHighlights.scss";
import ScEHighlightsCard from "../ScEHighlightsCard/ScEHighlightsCard";

export default class ScEHighlights extends Component{

  render() {
    return (
      <Container className="mt-5">

        <Row className="sc-e-highlights">

          <ScEHighlightsCard title={"Gratis WLAN"} icon={"fa-wifi"}>
            Auf unserem Platz haben Sie die Möglichkeit, unser kostenfreies WLAN zu nutzen.
          </ScEHighlightsCard>

          <ScEHighlightsCard title={"Restaurant"} icon={"fa-cutlery"}>
            Mit 47 Sitzplätzen innen plus Biergarten und Seeterrasse mit wunderschönem Blick auf den
            Borgdorfer See. Gutbürgerliche, frische Küche mit abwechslungsreichen Saisonkarten sowie Themenabenden.
          </ScEHighlightsCard>

          <ScEHighlightsCard title={"Spielplatz"} icon={"fa-cubes"}>
            Für unsere kleinen Gäste gibt es einen Spielplatz.
          </ScEHighlightsCard>

          <ScEHighlightsCard title={"Kiosk"} icon={"fa-coffee"}>
            Unser Kiosk bietet Ihnen fast rund um die Uhr kleine Leckereien und Kaffee&shy;spezialitäten.
          </ScEHighlightsCard>

          <ScEHighlightsCard title={"Badestelle"} icon={"fa-shower"}>
            Genießen Sie ein abkühlendes Bad im Borgdorfer See.
          </ScEHighlightsCard>

          <ScEHighlightsCard title={"Hunde willkommen"} icon={"fa-paw"}>
            Hunde sind bei uns willkommen, das Mitführen eines Gassibeutels sowie das Entfernen von Kot sind Pflicht,
            wir bitten Sie Ihren Hund auf dem gesamten Gelände an der Leine zu führen.
          </ScEHighlightsCard>

          <ScEHighlightsCard title={"Freies Parken"} icon={"fa-automobile"}>
            Sie finden mehrere Besucher-Parkplätze auf unserem Gelände. Auf Ihrer Parzelle ist immer ein PKW-Stellplatz
            inklusive.
          </ScEHighlightsCard>

          <ScEHighlightsCard title={"Schattenplätze"} icon={"fa-umbrella"}>
            Durch die ideale Lage mitten im Grünen sind viele Parzellen teils oder ganz im Schatten.
          </ScEHighlightsCard>

          <ScEHighlightsCard title={"Wäsche"} icon={"fa-bullseye"}>
            Sie finden in unserem Sanitärhaus Waschmaschinen und Trockner.
          </ScEHighlightsCard>

          <ScEHighlightsCard title={"Duschen"} icon={"fa-shower"}>
            Die Duschen können minutenweise für 0,30 € benutzt werden, es befindet sich ein Sanitärhaus im zentralen
            Mittelpunkt auf unserem Platz.
          </ScEHighlightsCard>

          <ScEHighlightsCard title={"Tischtennis"} icon={"fa-search"}>
            Am Wasser finden Sie eine Tischtennisplatte.
          </ScEHighlightsCard>

        </Row>
      </Container>
    );
  }

}
