/*
 * ScImageRow.js
 *
 * @version 27.07.20, 21:24
 * @since 27.07.20, 21:24
 * @author dev@webserver.ws
 * @copyright © 1997-2020 webserver.ws
 * @license All rights reserved
 * @link https://webserver.ws
 */

import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import {LazyLoadImage} from "react-lazy-load-image-component";
import PropTypes from "prop-types";

export default class CmsImageRow extends Component {

  render() {
    let css = 'my-5 align-items-center';
    if(this.props.reverse) {
      css+= ' flex-md-row-reverse';
    }

    return(
      <Row className={css}>
        <Col md={6}>
          <LazyLoadImage className="card-img-top thumbnail shadow-sm rounded"
                         alt={this.props.alt} src={this.props.image}/>
        </Col>
        <Col md={6}>
          {this.props.children}
        </Col>
      </Row>
    );
  }

}
CmsImageRow.propTypes = {
  image: PropTypes.any.isRequired,
  alt: PropTypes.string.isRequired,
  reverse: PropTypes.bool.isRequired,
}
