/*
 * ScHero.js
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import './ScHero.scss';

/**
 * Transparentes Panel mit primary-background.
 */
export class ScHeroInfoPrimary extends Component {
  render() {
    return (
      <div className="sc-hero-info">
        <div className="sc-hero-info-primary-text">{this.props.children}</div>
      </div>
    );
  }
}

/**
 * Transparentes Panel mit danger-background.
 */
export class ScHeroInfoDanger extends Component {
  render() {
    return (
      <div className="sc-hero-info">
          <div className="sc-hero-info-danger-text">{this.props.children}</div>
      </div>
    );
  }
}
