/*
 * RouteOeffnungszeiten.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import ScNavBarSpacer from "../../components/ScNavBarSpacer/ScNavBarSpacer";
import ScFooter from "../../components/ScFooter/ScFooter";
import {Container} from "react-bootstrap";
import ScOeffnungszeiten from "../../components/ScOeffnungszeiten/ScOeffnungszeiten";

export default class RouteOeffnungszeiten extends Component {

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.
  }

  render() {
    return (
      <div>
        <Helmet>
            <title>Seecampingplatz BUM » Öffnungszeiten</title>
            <meta name="description" content="Unsere Öffnungszeiten" />
        </Helmet>
        <ScNavBar />
        <ScNavBarSpacer/>
        <Container className={"mt-5"}>
          <ScOeffnungszeiten pageTitle="Öffnungszeiten Campingplatz" apiEndpoint={'oeffnungszeiten/2'}/>
        </Container>
        <ScFooter/>
      </div>
    );
  }
}

