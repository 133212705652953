import React, {Component} from "react";
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import Container from "react-bootstrap/Container";
import ScFooter from "../../components/ScFooter/ScFooter";
import {ScSpeisekarte} from "../../components/ScSpeisekarte/ScSpeisekarte";
import {Col, Row} from "react-bootstrap";
import CmsHeadSeo from "../../components/Cms/CmsHeadSeo";
import ScContentMD from "../../components/ScContentMD/ScContentMD";
import {Link} from "react-router-dom";

export default class RoutePartyserviceKarte extends Component{

  constructor(props) {
    super(props);
    this.state = {
      title: ["Partyservice","Partyservicekarte"],
      description: "Unsere Karte besteht aus verschiedenen Kategorien. Von Hauptspeisen, Beilagen und Dessert-Angeboten bis hin zu unterschiedlichen Buffets als Komplettlösung Ihrer Feier oder Veranstaltung. Sie können individuell nach Ihren Wünschen das Menü zusammenstellen.",
      lang: "de_DE",
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.
  }

  render() {
    return (
      <div>
        <CmsHeadSeo apiEndpoint="/cms/local/pages/partyservicekarte/seo.json"/>
        <ScNavBar spacer/>

        <Container>

          <Row>
            <Col md={2}/>
            <Col md={8}>

              <ScContentMD apiEndpoint="/cms/local/pages/partyservicekarte/content.md" />

            </Col>
            <Col md={2}/>
          </Row>

          <ScSpeisekarte id={4}/>

          <Row className="my-5">
            <Col sm={1}/>
            <Col sm={4}><
              Link className="mb-3 btn btn-primary btn-block font-weight-bold" to={'/partyservice'}>Partyservice, Catering und mehr ...</Link>
            </Col>
            <Col sm={1}/>
            <Col sm={1}/>
            <Col sm={4}>
              <Link className="mb-3 btn btn-secondary btn-block font-weight-bold"  to={'/de/kontakt'}>Kontakt</Link>
            </Col>
            <Col sm={1}/>
          </Row>

        </Container>

        <ScFooter/>
      </div>
    );
  }

}