/*
 * RouteStellplaetze.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import ScNavBarSpacer from "../../components/ScNavBarSpacer/ScNavBarSpacer";
import ScFooter from "../../components/ScFooter/ScFooter";
import ScEStellplaetzeWohnwagen from "../../elements/ScEStellplaetzeWohnwagen/ScEStellplaetzeWohnwagen";

export default class RouteStellplaetze extends Component {

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.
  }

  render() {
    return (
      <div>

        <Helmet>
            <title>Seecampingplatz BUM » Stellplätze</title>
            <meta name="description" content="Bei uns finden Sie Stellplätze für Zelte, Wohnwagen und Wohnmobile.
            Wohnbobilplätze direkt am See." />
        </Helmet>

        <ScNavBar />
        <ScNavBarSpacer/>


        <ScEStellplaetzeWohnwagen/>

        <ScFooter/>

      </div>
    );
  }
}

