/*
 * ScEListPreis.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, {Component} from "react";
import "./ScEListPreis.scss"
import {XFormat, XKey} from "../../components/ScToolbox/ScToolbox";

export default class ScEListPreis extends Component {

  render() {
    return (
      <li className="list-group-item d-flex justify-content-between align-items-top">
        <div>
          <h3>{this.props.title}</h3>
          {this.props.children}
        </div>
        <div className="text-right">
          {this.props.preis.map(preis => {

            let currency = preis.preis;
            if(!isNaN(preis.preis)) {
              currency = XFormat.currency(preis.preis) + " €";
            }

            return (
              <div key={XKey.getUniqueKey()} className="mb-2">
                <div className="text-nowrap font-weight-bold">{currency}</div>
                <div className="text-nowrap small">{preis.text}</div>
              </div>
            )
          })}
        </div>
      </li>
    );
  }

}
