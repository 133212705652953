/*
 * ScEStellplaetzeWohnwagen.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {LazyLoadImage} from "react-lazy-load-image-component";
import image1 from "../../../assets/images/wohnwagen-mit-sonnensegel.jpg";
// import image2 from "../../assets/images/mobilheim1.jpg";
import image3 from "../../../assets/images/schlafhuette-he20.jpg";
import "./ScEStellplaetzeWohnwagen.scss";
import {Link} from "react-router-dom";

export default class ScEStellplaetzeWohnwagen extends Component {

  render() {
    return (
      <section className="sc-e-stellplaetze-wohnwagen my-5">

        <Container className="mt-5">
          <Row className="mt-5">
            <Col md={6}>
              <LazyLoadImage className="card-img-top thumbnail shadow-sm rounded"
                             alt={'Wohnwagen mit Sonnensegel'}
                             src={image1}
              />
            </Col>
            <Col md={6} className="">
              <h2 className="mb-5">Stellplätze für Wohnwagen und Wohnmobile</h2>
              <p>Die Stellplätze auf dem Seecampingplatz BUM sind für Wohnwagen und Wohnmobile ausgelegt.</p>
              <ul>
                <li>Auf dem Stellplatz ist ein Stromanschluss möglich.</li>
                <li>In wenigen Metern finden Sie unsere Sanitärräume.</li>
                <li>Alle Plätze sind inkl eines PKW-Stellplatzes.</li>
              </ul>
            </Col>
          </Row>
        </Container>

        <Container className="mt-5">
          <Row className="mt-5 flex-row-reverse">
            <Col md={6}>
              <LazyLoadImage className="card-img-top thumbnail shadow-sm rounded"
                             alt={'Mobilheim'}
                             src={'/images/mietunterkuenfte/1.jpg'}
              />
            </Col>
            <Col md={6} className="mb-5">
              <h2 className="mb-5">Urlaub im Mobilheim</h2>
              <ul>
                <li>Für maximal 3 Personen</li>
                <li>Mit Doppelbett und ausziehbarem Schlafsofa (inkl. Bettwäsche)</li>
                <li>Küche, Duschbad und TV</li>
                <li>1 PKW-Stellplatz</li>
              </ul>
              <div className="text-center">
                <Link to="/mietunterkuenfte" className="btn btn-primary">Alle Mietunterkünfte entdecken <i className="fa fa-arrow-right"/></Link>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="mt-5">
          <Row className="mt-5">
            <Col md={6}>
              <LazyLoadImage className="card-img-top thumbnail shadow-sm rounded"
                             alt={'Schlafhütte'}
                             src={'/images/mietunterkuenfte/4.jpg'}
              />
            </Col>
            <Col md={6} className="mb-5">
              <h2 className="mb-5">Schlafhütte mieten</h2>
              <ul>
                <li>Für maximal 3 Personen</li>
                <li>1 PKW-Stellplatz</li>
                <li>Sanitärhaus in 100 m</li>
              </ul>
              <div className="text-center">
                <Link to="/mietunterkuenfte" className="btn btn-primary">Alle Mietunterkünfte entdecken <i className="fa fa-arrow-right"/></Link>
              </div>
            </Col>
          </Row>
        </Container>

      </section>
    );
  }

}