/*
 * @author <dev@sojoworld.de>
 * @copyright © 1997-2016 The "Project Sojoworld" community
 * @link https://sojoworld.de/en/docs/project-sojoworld/frontend
 * @license AGPL v3 or later
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class IfTrue extends Component {

  render() {
    if(typeof this.props.value !== "boolean" || !this.props.value) {
      return null
    }
    return (
      <>
        {this.props.children}
      </>
    );
  }
}
IfTrue.propTypes = {
  value: PropTypes.any,
};
