/*
 * ScConfig.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 15:52
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

export class ScConfig {

  static getApp() {
    return 'wwwdata RT' + process.env.REACT_APP_DEBIAN_VERSION + ' Website Frontend';
  }

  static getDomains() {
    return ['localhost',
      'seecampingbum.de',
    ]
  }

  static getTwitterName() {
    return '@';
  }

  static getLicenseID() {
    return 'SC20450';
  }

  static getTitleCustomer() {
    return 'Seecamping BUM';
  }

  static getApiEndpoint() {
    // if(window.location.hostname==='seecampingbum.de') {
      return 'https://api.seecampingbum.de/';
    // } else {
    //   return 'https://localhost/api/';
    // }
  }

  static getHelpUrl(localLink) {
    return '' + localLink;
  }

}
