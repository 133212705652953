/*
 * ScFooter.js
 *
 * @version 05.10.20, 16:17
 * @since 26.07.20, 10:34
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import './ScFooter.scss';
import ScMenuFooter from "../ScMenuFooter/ScMenuFooter";

export default class ScFooter extends Component {

  render() {
    return (
      <div className="sc-footer pb-5 pt-5 mt-5">
        <div>
          <ScMenuFooter/>
        </div>
        <div className="mt-2 copyright">&copy; 2021 Seecamping BUM</div>
      </div>
    );
  }
}
