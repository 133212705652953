/*
 * RouteFrontPage.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import ScEHero from "../../elements/ScEHero/ScEHero";
import Container from "react-bootstrap/Container";
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import ScFooter from "../../components/ScFooter/ScFooter";
import "./RouteFrontPage.scss";
import ScEHighlights from "../../elements/ScEHighlights/ScEHighlights";
import ScEStellplaetzeWohnwagen from "../../elements/ScEStellplaetzeWohnwagen/ScEStellplaetzeWohnwagen";
import ScEStellplaetzeWohnmobile from "../../elements/ScEStellplaetzeWohnmobile/ScEStellplaetzeWohnmobile";
import {ScTextbox} from "../../components/ScTextbox/ScTextbox";

export default class RouteFrontPage extends Component {

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Seecampingplatz BUM - Im Herzen von Schleswig-Holstein</title>
          <meta name="description" content="Camping am Borgdorfer See im Naturpark Westensee. Im Herzen von Schleswig-Holstein
            im Städtedreieck Kiel - Neumünster - Rendsburg" />
        </Helmet>
        <ScNavBar />
        <ScEHero/>

        <Container>
          <ScTextbox id={3}
                     cssClassWrapper="alert alert-danger mt-5 sc-no-margin-bottom-last-child"
                     cssClassBox=""/>
        </Container>

        <ScEStellplaetzeWohnwagen/>

        <ScEStellplaetzeWohnmobile/>

        <ScEHighlights/>

        <Container>
            <p></p>
        </Container>

        <ScFooter/>
      </div>
    );
  }
}

