/*
 * ScHeadSeo.js
 *
 * @version 06.08.20, 22:12
 * @since 06.08.20, 22:12
 * @author dev@webserver.ws
 * @copyright © 1997-2020 webserver.ws
 * @license All rights reserved
 * @link https://webserver.ws
 */

import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import {ScConfig} from "../ScConfig/ScConfig";

export default class CmsHeadSeo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      locale: "",
      title: null,
      description: null,
      image: null
    };
    this.loadData();
  }

  loadData() {
    let apiEndPoint = this.props.apiEndpoint;
    fetch(apiEndPoint)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            locale: result.locale,
            title: result.title,
            description: result.description,
            image: result.image
          });
          // console.log(result);
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    // if(!this.state.isLoaded) {
    //   return null;
    // }
    let twitterName = ScConfig.getTwitterName();
    return(
      <Helmet>
        <title>{this.state.title}</title>
        <meta name="description" content={this.state.description} />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:locale" content={this.state.locale} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={this.state.title} />
        <meta property="og:description" content={this.state.description} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content={ScConfig.getTitleCustomer()} />
        {/*Google / Search Engine Tags*/}
        <meta itemProp="name" content={this.state.title} />
        <meta itemProp="description" content={this.state.description} />
        {typeof(this.state.image) === "string" &&
        <meta itemProp="image" content={"https://" + window.location.hostname + this.state.image} />
        }
        {/*Facebook Meta Tags*/}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={this.state.title} />
        <meta property="og:description" content={this.state.description} />
        {typeof(this.state.image) === "string" &&
        <meta property="og:image" content={"https://" + window.location.hostname + this.state.image} />
        }
        {/*Twitter Meta Tags*/}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={this.state.title} />
        <meta name="twitter:description" content={this.state.description} />
        {typeof (this.state.image) === "string" &&
        <meta name="twitter:image" content={"https://" + window.location.hostname + this.state.image} />
        }
        {typeof (twitterName) === "string" &&
        <meta name="twitter:site" content={twitterName}/>
        }
        {typeof (twitterName) === "string" &&
        <meta name="twitter:creator" content={twitterName} />
        }
      </Helmet>
    );
  }
}
CmsHeadSeo.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
}