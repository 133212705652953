/*
 * RouteRestaurantSpeisekarteRestaurant.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, {Component} from "react";
import {Helmet} from "react-helmet";
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import ScNavBarSpacer from "../../components/ScNavBarSpacer/ScNavBarSpacer";
import Container from "react-bootstrap/Container";
import ScFooter from "../../components/ScFooter/ScFooter";
import {ScSpeisekarte} from "../../components/ScSpeisekarte/ScSpeisekarte";

export default class RouteRestaurantSpeisekarteRestaurant extends Component{

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    window.scrollTo(0,0); // Setze die Position des Contents auf 0.
  }

  render() {
    return (
      <div>

        <Helmet>
          <title>Seecampingplatz BUM » Restaurant Seeblick » Speisekarte Restaurant</title>
          <meta name="description" content="Seecampingplatz BUM - Restaurant Seeblick - Speisekarte Restaurant. Alle
          Speisen auch zum Mitnehmen. " />
        </Helmet>

        <ScNavBar />
        <ScNavBarSpacer/>

        <Container className="mt-5">

          <h1 className="text-center">Speisekarte Restaurant Seeblick</h1>

          <p className="text-center font-weight-bold mt-5">Alle Speisen auch zum Mitnehmen.</p>

          <p className="text-center my-5">
            <a href="tel://+49439284840" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
              Telefonische Vorbestellung unter <span className="text-nowrap">04392-84840</span>
            </a>
          </p>

          <ScSpeisekarte id={1}/>

          <h1 className="mt-5 text-center">Getränkekarte</h1>

          <ScSpeisekarte id={3}/>

          <p className="text-center font-weight-bold mt-5">
            Restaurant Seeblick auf dem Campingplatz BuM
          </p>

          <p className="text-center font-weight-bold">
            Vorbestellung unter <a href="tel://+49439284840" target="_blank" rel="noopener noreferrer" >04392-84840</a>
          </p>

        </Container>

        <ScFooter/>
      </div>
    );
  }

}