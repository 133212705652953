/*
 * ScPRestaurantHero.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import './ScPRestaurantHero.scss';
import hero from '../../../assets/images/restaurantseeblick-hero.jpg';
import ScArrowScrollDown from "../../components/ScArrowScrollDown/ScArrowScrollDown";
import {Link} from "react-router-dom";
import {ScTextbox} from "../../components/ScTextbox/ScTextbox";
import {ScHeroInfoPrimary} from "../../components/ScHero/ScHero";
import {Container} from "react-bootstrap";

export default class ScPRestaurantHero extends Component {

render() {
    let style = {
        backgroundImage: `url(${hero})`,
    };
    return (
        <div>
            <div className="sc-p-restaurant-hero" style={style}>

                <ScHeroInfoPrimary>
                    <p className="sc-hero-text">Restaurant Seeblick</p>
                    <p className="sc-hero-text2">am Borgdorfer See</p>
                </ScHeroInfoPrimary>

                <ScTextbox id={2} cssClassWrapper="sc-textbox-center" cssClassBox="sc-textbox-danger"/>

                <Container>
                    <ScTextbox id={6} cssClassWrapper="jumbotron rounded bg-warning text-black m-2 p-1" cssClassBox="text-center">
                        <p className="text-center">
                            <Link to="/restaurant/speisekarte/restaurant" className="btn btn-primary m-2">Speisekarte Restaurant</Link>
                            <Link to="/restaurant/speisekarte/ausserhaus" className="btn btn-primary m-2">Speisekarte Außerhaus</Link>
                        </p>
                    </ScTextbox>
                </Container>

                <ScArrowScrollDown/>
            </div>
        </div>
    );
}
}