/*
 * ScEStellplaetzeWohnmobile.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, {Component} from "react";
import {Col} from "react-bootstrap";
import image1 from "../../../assets/images/wohnmobilplatz.jpg";
import "./ScEStellplaetzeWohnmobile.scss";

export default class ScEStellplaetzeWohnmobile extends Component {

  render() {
    let style = {
      backgroundImage: `url(${image1})`,
    };
    return (
      <section className="sc-e-stellplaetze-wohnmobile row align-items-center" style={style}>
        <Col md={6}>

        </Col>
        <Col md={6} className="p-4 text-center">
          <h2>Wohnmobilplätze</h2>
          <p><strong>direkt am Borgdorfer See</strong></p>
        </Col>
      </section>
    );
  }

}