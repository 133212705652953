/*
 * Route404.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {Helmet} from 'react-helmet';
import ScNavBar from "../../components/ScNavBar/ScNavBar";
import ScNavBarSpacer from "../../components/ScNavBarSpacer/ScNavBarSpacer";

export default class Route404 extends Component {
  render() {
    return (
      <div>

        <Helmet>
            <title>Seite nicht gefunden</title>
            <meta name="description" content="Diese Seite ist nicht (mehr) vorhanden" />
        </Helmet>

        <ScNavBar />
        <ScNavBarSpacer/>

        <div className="container text-center mt-5">
          <p className="sc-h-info">Diese Seite ist nicht (mehr) vorhanden</p>
          <p>
            <Link to="/">Weiter zur Startseite</Link>
          </p>
        </div>

      </div>
    );
  }
}

