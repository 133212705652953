/*
 * ScMenuFooter.js
 *
 * @version 02.12.20, 23:44
 * @since 02.12.20, 23:44
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, {Component} from 'react';
import {ScConfig} from "../ScConfig/ScConfig";
import {XKey} from "../ScToolbox/ScToolbox";
import {Link} from "react-router-dom";

export default class ScMenuFooter extends Component {

  Url;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    };
    this.loadContent();
  }

  loadContent() {
    let apiEndPoint = ScConfig.getApiEndpoint() + 'de/cms/menu/footer';
    fetch(apiEndPoint)
      .then(res => res.json())
      .then(
        (result) => {
          if(typeof result.result !== 'undefined') {
            this.setState({
              isLoaded: true,
              items: result.result,
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    if(!this.state.isLoaded) return null;
    return (
      <>
        {this.state.items.map((item)=>{
          return(<span key={XKey.getUniqueKey()}>&nbsp;<Link to={item.Url}>{item.Title}</Link>&nbsp;</span>);
        })}
      </>
    );
  }
}

