import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";

export default class DialogMapQrCode extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
    };
  }

  dialogClose() {
    this.setState({
      show: false,
    });
  }

  dialogShow() {
    this.setState({
      show: true,
    });
  }

  onHide() {
    // Dummy-function to catch event on overlay.
  }

  /**
   * Erzeugt den View.
   * @returns {JSX.Element}
   */
  render() {
    return (
      <Modal size="sm"
             show={this.state.show}
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        <Modal.Header className="align-items-center sc-modal-bg">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <Modal.Title>QR-Code</Modal.Title>
            <div>
              <i className="fa fa-remove sc-modal-header-close-button"
                 onClick={()=>this.dialogClose()}
                 title="Dialog schließen"/>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img alt=""
                 src="/content/qr-codes/homepage-karte.svg"
                 className="img-thumbnail card-img"/>

            <div>
              <a href="https://seecampingbum.de/campingplatzkarte">
                seecampingbum.de/campingplatzkarte
              </a>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="sc-modal-bg-footer">
          <Button variant="secondary" onClick={()=>this.dialogClose()}>
            <i className="fa fa-remove"/> Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
