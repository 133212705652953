/*
 * ScETableColPreis.js
 *
 * @version 05.10.20, 16:17
 * @since 05.10.20, 16:12
 * @author dev@wwwdata.org
 * @copyright © 1997-2020 wwwdata.org
 * @license All rights reserved
 * @link https://wwwdata.org
 */

import React, {Component} from "react";
import './ScETableColPreis.scss';
import {XFormat} from "../../components/ScToolbox/ScToolbox";

export default class ScETableColPreis extends Component {

  render() {
    return (
      <tr>
        <td>{this.props.item.title}</td>
        <td className="text-right font-weight-bold">{XFormat.currency(this.props.item.preis)} €</td>
      </tr>
    );
  }

}
